import React from 'react';
import { useRecoilValue } from 'recoil';

// Components
import { SimpleInput } from 'components/FormHelpers';
import DateTimePicker from 'ui/DateTimePicker';
// State
import { profileState } from 'state';

function Metadata() {
  const profile = useRecoilValue(profileState);
  if (!profile) return <div />;
  const {
    metadata: { authenticated, ipAddress, location }
  } = profile;
  return (
    <div className="grid gap-x-8 md:grid-cols-1 max-w-3xl">
      <DateTimePicker
        field={{
          name: 'authenticated',
          label: 'Last Login',
          value: authenticated || '',
          helpText: '',
          required: false,
          format: 'dddd DD/MM/yyyy HH:mm'
        }}
        showTime="HH:mm"
        value={authenticated || ''}
        errors={{}}
        disabled
        readOnly
        setFieldValue={() => {}}
        touched={{}}
      />
      <SimpleInput
        field={{ name: 'ipAddress', label: 'IP Address', placeholder: '', required: false }}
        value={ipAddress || ''}
        handleChange={() => {}}
        readOnly
        errors={{}}
        touched={{}}
      />
      <SimpleInput
        field={{ name: 'location', label: 'Location', placeholder: '', required: false }}
        value={location || ''}
        handleChange={() => {}}
        readOnly
        errors={{}}
        touched={{}}
      />
    </div>
  );
}

export default Metadata;
