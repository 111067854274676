import React from 'react';
import { useRecoilValue } from 'recoil';

// Components
import { SimpleInput } from 'components/FormHelpers';
// State
import { profileState } from 'state';

function Statistics() {
  const profile = useRecoilValue(profileState);
  if (!profile) return <div />;
  const {
    statistics: { booked, cancelled, completed, noShow }
  } = profile;
  return (
    <div className="grid gap-x-8 md:grid-cols-1 max-w-3xl">
      <SimpleInput
        field={{ name: 'booked', label: 'Booked', placeholder: '', required: false, type: 'number' }}
        value={booked || 0}
        handleChange={() => {}}
        readOnly
        errors={{}}
        touched={{}}
      />
      <SimpleInput
        field={{ name: 'completed', label: 'Completed', placeholder: '', required: false, type: 'number' }}
        value={completed || 0}
        handleChange={() => {}}
        readOnly
        errors={{}}
        touched={{}}
      />
      <SimpleInput
        field={{ name: 'cancelled', label: 'Cancelled', placeholder: '', required: false, type: 'number' }}
        value={cancelled || 0}
        handleChange={() => {}}
        readOnly
        errors={{}}
        touched={{}}
      />
      <SimpleInput
        field={{ name: 'noShow', label: 'No Show', placeholder: '', required: false, type: 'number' }}
        value={noShow || 0}
        handleChange={() => {}}
        readOnly
        errors={{}}
        touched={{}}
      />
    </div>
  );
}

export default Statistics;
