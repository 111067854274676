/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
import { toast } from 'react-toastify';

// Ajax Calls
import { updatePassword } from 'ajax/auth';
// UI Components
import { SimpleInput } from 'components/FormHelpers';
import { PrimaryButton, DefaultButton } from 'ui/Button';
import ReactModal from 'ui/ReactModal';
import { ReactConfirmAlert } from 'ui/ReactConfirmAlert';
// Assets
import { DangerAlert } from 'ui/Alerts';

function ProfilePassword() {
  const [isDataChanged, setIsDataChanged] = useState(false);
  const [confirmModal, setConfirmModal] = useState({ status: false, mode: '', message: '' });
  // Creating Schema for Password Match Validation
  const PasswordMatchSchema = Yup.object({
    currentPassword: Yup.string().required('Required'),
    newPassword: Yup.string()
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[\x21-\x2E,\x3A-\x40,\x5B-\x60,\x7B-\x7E,\u00A3,\u00A6,\u00AC,\u20AC]+)[A-Za-z\d\x21-\x2F,\x3A-\x40,\x5B-\x60,\x7B-\x7E,\u00A3,\u00A6,\u00AC,\u20AC]{8,}$/,
        'Please choose a stronger password with at least 8 characters. Try a mix of letters, numbers and symbols.'
      )
      .required('Required'),
    confirmNewPassword: Yup.string()
      .required('Required')
      .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
  });
  // Copy Original Data
  const ORIGINAL_DATA = {
    currentPassword: '',
    newPassword: '',
    confirmNewPassword: ''
  };
  // Capture Password
  const formik = useFormik({
    initialValues: ORIGINAL_DATA,
    validationSchema: PasswordMatchSchema,
    onSubmit: async (data) => {
      const { data: formData, status } = await updatePassword({
        currentPassword: data.currentPassword,
        newPassword: data.newPassword
      });
      if (status !== 200) {
        if (formData?.type === 'Validation') {
          return formData?.data?.map((error) => toast.error(error.message));
        }
        if (formData?.data) {
          return formData?.data?.map((error) => toast.error(error.message));
        }
        return toast.error(formData.message);
      }
      resetForm();
      return toast.success('Password Updated.');
    }
  });

  const { values, errors, touched, isSubmitting, handleChange, handleBlur, handleSubmit, resetForm } = formik;

  // Check if Data is Changed
  const checkDataChangedHandler = () => {
    if (JSON.stringify(values) === JSON.stringify(ORIGINAL_DATA)) {
      return setIsDataChanged(false);
    }
    if (isDataChanged) return null;
    return setIsDataChanged(true);
  };

  // Discard Changes Handler
  const discardChangesHandler = () => {
    resetForm();
    setConfirmModal({ status: false, mode: '', message: '' });
  };

  // Run Effect EveryTime Data Changes
  useEffect(() => {
    checkDataChangedHandler();
  }, [values]);

  return (
    <>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <div className="grid gap-x-8 md:grid-cols-1 max-w-3xl">
            <div>
              <SimpleInput
                field={{
                  name: 'currentPassword',
                  label: 'Current Password',
                  required: true,
                  type: 'password'
                }}
                value={values.currentPassword}
                handleChange={handleChange}
                errors={errors}
                touched={touched}
              />
            </div>
            <div>
              <SimpleInput
                field={{ label: 'New Password', type: 'password', name: 'newPassword', required: true }}
                value={values.newPassword}
                handleChange={handleChange}
                handleBlur={handleBlur}
                errors={{}}
                touched={touched}
              />
              {errors.newPassword && touched.newPassword && (
                <DangerAlert title={errors.newPassword} className="-mt-3 mb-2" />
              )}
            </div>
            <div>
              <SimpleInput
                field={{
                  label: 'Confirm New Password',
                  type: 'password',
                  name: 'confirmNewPassword',
                  required: true
                }}
                value={values.confirmNewPassword}
                handleChange={handleChange}
                handleBlur={handleBlur}
                errors={{}}
                touched={touched}
                disabled={values.newPassword === '' || errors.newPassword}
              />
              {errors.confirmNewPassword && touched.confirmNewPassword && (
                <DangerAlert title={errors.confirmNewPassword} className="-mt-3 mb-2" />
              )}
            </div>
          </div>
          <div className="flex">
            <PrimaryButton
              type="submit"
              text="Save"
              className="mr-2"
              disabled={isSubmitting}
              readOnly={values.newPassword === '' || errors.newPassword}
            />
            {isDataChanged && (
              <DefaultButton
                text="Discard"
                onClick={() =>
                  setConfirmModal({
                    mode: 'discard',
                    status: true,
                    message: 'Are you sure you want to discard the changes?'
                  })
                }
              />
            )}
          </div>
        </Form>
      </FormikProvider>
      {confirmModal.status && (
        <ReactModal>
          <ReactConfirmAlert
            message={confirmModal.message}
            buttonText="Yes"
            disabled={false}
            onConfirm={
              confirmModal.mode === 'discard'
                ? () => discardChangesHandler()
                : confirmModal.mode === 'concurrency'
                ? () => window.location.reload()
                : null
            }
            onClose={() => setConfirmModal({ status: false, mode: '', message: '' })}
          />
        </ReactModal>
      )}
    </>
  );
}

export default ProfilePassword;
